<template>
  <div>
    <!--begin::Row-->
    <!-- <div class="row">
        <div class="col-lg-12"> -->
            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header border-0">
                    <h3 class="card-title font-weight-bolder text-dark">Rekapitulasi Nomine Kementerian/Lembaga</h3>
                    <div class="card-toolbar">
                        
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div class="mb-12">
                        <!--begin::Content-->
                        <div>
                            <div style="overflow-x:auto;overflow-y: hidden;">
                                <table id="example" class="table table-striped table-bordered" style="width:100%">
                                  <thead>
                                      <tr>
                                          <th rowspan="2">No</th>
                                          <th rowspan="2">Nama KL</th>
                                          <th style="text-align:center;">Kinerja PPB</th>
                                          <th rowspan="2">Nilai Akhir</th>
                                          <th rowspan="2">Peringkat Sementara</th>
                                          <th rowspan="2">Peringkat Baru</th>
                                          <th rowspan="2">Catatan</th>
                                          <th rowspan="2">Aksi</th>
                                      </tr>
                                      <tr>
                                          <th style="text-align:center;">PM PPB</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr v-for="row in totskor" :key="row.id">
                                          <td></td>
                                          <td>{{row.nama_kl}}</td>
                                          <td><div style="width:130px">{{format_desimal(row.total_ppb)}} &nbsp; &nbsp;<b-button @click="urlppb(row.idkl)" size="sm" style="background-color:#F9F9F9;" v-b-tooltip.hover title="Lihat detail penilaian"><i class="flaticon-eye"></i></b-button></div></td>
                                          <td><div style="width:130px">{{format_desimal(row.total_akhir)}}</div></td>
                                          <td><div style="text-align:center;">{{row.peringkat_awal}}</div></td>
                                          <td>
                                              <div style="text-align:center;" v-if="row.peringkat_final == 9999">
                                                  Keluar
                                              </div>
                                              <div style="text-align:center;" v-else>{{row.peringkat_final}}</div>
                                          </td>
                                          <td>
                                              <button data-toggle="modal" data-target=".bd-example-modal-lg" @click="getcatatan(row.idkl)" style="margin:5px" class="btn btn-primary">Lihat <span v-if="row.punya_catatan" class="badge badge-danger">!</span></button>
                                          </td>
                                          <td>
                                            <div v-if="row.peringkat_final == 9999">
                                                  <button @click="setPeringkatBaru(row.idkl)" hidden class="btn btn-danger">Keluar</button>
                                              </div>
                                              <div v-else>
                                                  <button @click="setPeringkatBaru(row.idkl)" class="btn btn-danger">Keluar</button>
                                              </div>
                                          </td>
                                          
                                            <!-- <td></td> -->
                                          <!-- <td>t.nixon@datatables.net</td> -->
                                      </tr>
                                  </tbody>
                              </table>
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->
                </div>
                <!--end: Card Body-->
                
            </div>

            <div class="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Catatan</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <div>
                                <div style="overflow-x:auto;">
                                    <table id="example2" class="table table-striped table-bordered" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>No</th>
                                                <th>Judul Kajian/Berita</th>
                                                <th>Tipe Kajian/Berita</th>
                                                <th>File</th>
                                                <th>Aksi</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="row in catatann" :key="row.id">
                                                <td></td>
                                                <td>{{row.judul}}</td>
                                                <td>
                                                    <div v-if="row.tipe === 'Negatif'" style="color:red">{{row.tipe}}</div>
                                                    <div v-else style="color:blue">{{row.tipe}}</div>
                                                </td>
                                                <td>
                                                    <div v-if="row.file != null">
                                                        <a :href="row.file" target="_blank" class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i class="fa fa-download" aria-hidden="true"/></a>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <button data-toggle="modal" data-target="#modal-edit" @click="getPerCatatan(row.id)" style="margin:5px" class="btn btn-secondary" v-b-tooltip.hover title="Lihat"><i class="fa fa-eye"></i></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                        </div>
                        </div>
                </div>
            </div>

            <div class="modal fade" id="modal-edit" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div class="modal-dialog modal-xl" role="document">
                        <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle">Lihat Catatan</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                           <form ref="form" @submit.stop.prevent="tambahcatatan">
                   

                                    <div class="form-group row">
                                        <label class="col-4">Tipe</label>
                                        <div class="col-8">
                                            <b-form-select disabled v-model="getCatatan.tipe" :options="tipe"></b-form-select>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Penghimpun Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <input disabled type="text" v-model="getCatatan.penghimpun" class="form-control"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Judul Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <input disabled type="text" v-model="getCatatan.judul" class="form-control"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Lembaga</label>
                                        <div class="col-lg-8">
                                            <input disabled type="text" v-model="getCatatan.lembaga" class="form-control"/>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                    <label class="col-lg-4 col-form-label">Rentang Waktu</label>
                                        <div class="col-lg-8">
                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <b-form-datepicker disabled  v-model="getCatatan.tanggal_awal" locale="in" class="mb-2"></b-form-datepicker>
                                                    </div>
                                                    <!--end::Input-->
                                                </div>
                                                <div class="col-xl-6">
                                                    <!--begin::Input-->
                                                    <div class="form-group">
                                                        <b-form-datepicker disabled  v-model="getCatatan.tanggal_akhir" locale="in" class="mb-2"></b-form-datepicker>
                                                    </div>
                                                    <!--end::Input-->
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Ringkasan Isi Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <!-- <vue-editor disabled v-model="getCatatan.deskripsi" /> -->
                                            <div v-html="getCatatan.deskripsi"></div>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Tautan Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <input  disabled type="text" v-model="getCatatan.sumber" class="form-control"/>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label class="col-lg-4 col-form-label">Unggah Kajian/Berita</label>
                                        <div class="col-lg-8">
                                            <b-form-file v-model="getCatatan.file" type="file" ref="file"></b-form-file>
                                            <div v-if="getCatatan.file !== null">
                                                <a :href="getCatatan.file" target="_blank" class="btn btn-text-dark-50 btn-icon-success font-weight-bold btn-hover-bg-light mr-3 btn-lg"><i class="fa fa-download" aria-hidden="true"/></a>
                                            </div>
                                        </div>
                                    </div>
                </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Tutup</button>
                            
                        </div>
                        </div>
                    </div>
                </div>

            <div class="card card-custom card-stretch gutter-b">
                <!--begin::Header-->
                <div class="card-header">
                    <div class="card-title">
                    <h3 class="card-label">Daftar Tim Penilai</h3>
                    </div>
                </div>
                <!--end::Header-->
                <!--begin::Body-->
                <div class="card-body pt-0">
                    <!--begin::Item-->
                    <div>
                        <!--begin::Content-->
                        <div>
                            <div data-app>
                                <v-card-title>
                                <v-spacer></v-spacer>
                                <v-text-field
                                    v-model="search"
                                    append-icon="search"
                                    label="Search"
                                    single-line
                                    hide-details
                                ></v-text-field>
                                </v-card-title>
                                <v-data-table
                                :headers="headersTimPenilai"
                                :items="timpenilai"
                                :search="search"
                                >
                                <template v-slot:item="row">
                                        <tr>
                                            <td>{{row.item.nama}}</td>
                                            <td>{{row.item.instansi}}</td>
                                            <td>{{row.item.jabatan}}</td>
                                            <td>
                                                <span v-if="row.item.persetujuan === 'Setuju'" class="badge badge-pill badge-success"> Setuju </span>
                                                <span v-else class="badge badge-pill badge-danger">Tidak Setuju </span>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            <!-- </v-card> -->
                            </div>
                        </div>
                        <!--end::Content-->
                    </div>
                    <!--end::Item-->

                    <button @click="mengesahkan" style="margin-right:20px;" type="button" class="btn btn-primary">Mengesahkan</button>
                    <button data-toggle="modal" data-target="#daerah" class="btn btn-success"><i class="fa fa-print"></i> Cetak Berita Acara</button>
                </div>
                
                <!--end: Card Body-->
            </div>

             <!-- modal masukkan daerah-->
            <div class="modal fade" id="daerah" tabindex="-1" role="dialog" aria-labelledby="persetujuan" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="persetujuan">Menyetujui Penetapan Nomine</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div id="alasan" class="form-group row">
                        <label class="col-lg-4 col-form-label">Daerah</label>
                            <div class="col-lg-8">
                                <input type="text" v-model="daerah" class="form-control"/>
                            </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Batal</button>
                    <button type="button" class="btn btn-primary" @click="cetakba" style="background-color: #030A8C; border-color: #030A8C" data-dismiss="modal">Simpan</button>
                </div>
                </div>
            </div>
            </div>
        </div>
    <!-- </div>
  </div> -->
</template>
<style>
  div.messages {
  /* background-color: lightblue; */
  /* width: 110px; */
  height: 500px;
  overflow: auto;
}
</style>
<style lang="">
  
</style>
<script src="https://code.jquery.com/jquery-3.5.1.js"></script>
<script src="https://cdn.datatables.net/1.10.24/js/jquery.dataTables.min.js"></script>
<script src="https://cdn.datatables.net/buttons/1.7.0/js/dataTables.buttons.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/pdfmake.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.53/vfs_fonts.js"></script>
<script src="https://cdn.datatables.net/buttons/1.7.0/js/buttons.print.min.js"></script>
<script src="https://cdn.datatables.net/buttons/1.7.0/js/buttons.html5.min.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jszip/3.1.3/jszip.min.js"></script>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from 'axios';
import { VueEditor } from "vue2-editor";
import Swal from "sweetalert2";

export default {
  components:{VueEditor},
  data() {
      return {
        url: localStorage.getItem('baseapi'),
        token: localStorage.getItem('id_token'),
        test:'hai',
        tahun: new Date().getFullYear(),
        totskor:[],
        search: '',
        selectedperingkatbaru:'',
        peringkatbaru: [
         { value: null, text: '-' },
         { value: 1, text: 1 },
         { value: 2, text: 2 },
         { value: 3, text: 3 },
         { value: 4, text: 4 },
         { value: 5, text: 5 },
         { value: 1000, text: 'Keluar' },
       ],
        headers: [
            {
            text: 'Nama',
            align: 'left',
            sortable: false,
            value: 'name',
            },
            { text: 'Ket', value: 'ket' },
            { text: 'Icon', value: 'icon' },
            { text: 'Aksi', value: '' },
            
        ],
        headersTimPenilai:[
            {
            text: 'Nama',
            align: 'left',
            sortable: false,
            value: 'name',
            },
            { text: 'Instansi/Lembaga', value: 'instansi' },
            { text: 'Jabatan dalam Tim Penilai', value: 'jabatan' },
            { text: 'Persetujuan', value: 'persetujuan' },
            
        ],
        headerscatatan: [
            {
            text: 'Judul',
            align: 'left',
            value: 'judul',
            },
            { text: 'Tipe', value: 'tipe' },
            { text: 'Aksi', value: '' },
        ],
        searchcatatan:'',
        getCatatan:{
            id:'',
            tipe:'',
            penghimpun:'',
            judul:'',
            deskripsi:'',
            sumber:''
        },
        tipe: [
         { value: 'Negatif', text: 'Negatif' },
         { value: 'Positif', text: 'Positif' },
        ],
        noCatatan:'',
        timpenilai:[],
        catatann:[],
        daerah:'',
      }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Penetapan Nomine" }]);
    this.getdata()
  },
  methods: {
    getdata(){
      $('#example').DataTable().destroy();
        this.loaddata()
    },
    format_desimal(value){
         if (value) {
           return value.replace(".",",");
          }
    },
    loaddata(){
      axios.get(this.url+"/list_setuju_kl?filter=tahun,=,"+this.tahun, {
            headers: {
                'xth': this.token
            }
        })
        .then(response => {
            this.timpenilai = response.data.data;
        }).catch(error=>{
      console.log(error)
      return error
      // this.loaddata()
      });

      this.totskor = null;
      axios.get(this.url+"/total_nilai_kl_rank/list?sort=peringkat_final asc", {
            headers: {
                'xth': this.token
            }
        })
        .then(response => {
            var nominee = response.data.data
            this.totskor = nominee;
            this.initDatatable();
            // this.idpenilaian=response.data.data.id;
            console.log(response.data)
        }).catch(error=>{
          // localStorage.setItem('idpenilaian', 'undefined')
          console.log(error)
          return error
          // this.loaddata()
      });

            
    },
    getcatatan(idkl){
        $('#example2').DataTable().destroy();
        this.catatann = null,
        axios.get(this.url+"/catatan_ta_kl?filter=idkl,=,"+idkl+";tahun,=,"+this.tahun, {
            headers: {
                'xth': this.token
            }
        })
            .then(response => {
                var catatannomine = response.data.data
                this.catatann = catatannomine;
                this.initDatatable2()
            }).catch(error=>{
                return error
                });
    },
    getPerCatatan(id){
        axios.get(this.url+"/catatan_ta_kl?filter=id,=,"+id, {
            headers: {
                'xth': this.token
            }
        }).then(response => {
            this.getCatatan.id = id;
                this.getCatatan.tipe = response.data.data[0].tipe;
                this.getCatatan.penghimpun = response.data.data[0].penghimpun;
                this.getCatatan.judul = response.data.data[0].judul;
                this.getCatatan.deskripsi = response.data.data[0].deskripsi;
                this.getCatatan.sumber = response.data.data[0].sumber;
                this.getCatatan.tanggal_awal = response.data.data[0].tanggal_awal;
                this.getCatatan.tanggal_akhir = response.data.data[0].tanggal_akhir;
                this.getCatatan.lembaga = response.data.data[0].lembaga;
                this.getCatatan.idkl = response.data.data[0].idkl;
                this.getCatatan.file = response.data.data[0].file;
        });
    },
    setPeringkatBaru(idkl){
        Swal.fire({
        title: 'Apakah anda yakin?',
        text: "Anda tidak akan dapat mengembalikan ini!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        cancelButtonText: 'Batal',
        confirmButtonText: 'Ya'
        }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            const id = idkl;
            axios.post(this.url+"/total_nilai_kl_rank/kick",
            {
                idkl:id
            },{
                headers:{
                "xth": this.token
                }
            }).then((res) => {
                        Swal.fire('Berhasil diubah', '', 'success')
                        this.getdata()
                    return res;
                    })
                    .catch((err) => {
                        Swal.fire(err.response.data.message, '', 'error')    
                        return err;
                });
        }
        })
  
    },
    mengesahkan(){
      axios
            .post(
                this.url+'/total_nilai_kl_rank/nomine',
            {
                
            }
            , {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil melakukan pengesahan', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                console.log(res);
                this.getdata()
              return res;
            })
            .catch((err) => {
              console.log(err)
              this.$bvToast.toast('Gagal melakukan pengesahan', {
              title: `Failed `,
              variant: `danger`,
              solid: true
            })
            return err;
        });
    },
    cetakba(){
        const tahun = this.tahun;
        const daerah = this.daerah;
        axios
            .post(this.url+"/nominee_kl_ba",
            {
                tahun,
                daerah,
            
            }, {
                headers: {
                    'xth': this.token
                }
            })
            .then((res) => {
                this.$bvToast.toast('Berhasil Cetak BA', {
                    title: `Success `,
                    variant: `success`,
                    solid: true
                })
                window.open(res.data.data.file_ba, '_blank');
                
                // localStorage.setItem('idprofil', JSON.stringify(res.data.id))
            return res;
            })
            .catch((err) => {
            console.log(err)
            this.$bvToast.toast('Gagal Cetak BA', {
            title: `Failed `,
            variant: `danger`,
            solid: true
            })
            return err;
        });
    },
    initDatatable() {
        setTimeout(() => {
        var t =  $('#example').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            // order: [[5, 'asc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        // t.column(7, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
        //     cell.innerHTML = i+1;
        // } );
        } ).draw();
        }, 300)
    },
    initDatatable2() {
        setTimeout(() => {
        var t =  $('#example2').DataTable({
            "pagingType": "full_numbers",
            "language": {
                "info": "Menampilkan _START_ sampai _END_ dari _TOTAL_ data",
                "paginate": {
                    "first":      "Awal",
                    "last":       "Akhir",
                    "next":       "Selanjutnya",
                    "previous":   "Sebelumnya"
                },
            },
            "lengthMenu": [
                [10, 25, 50, -1],
                [10, 25, 50, "All"]
            ],
            // order: [[5, 'asc']],
            responsive: true,
            destroy: true,
            retrieve: true,
            autoFill: true,
            colReorder: true,

        });
        t.on( 'order.dt search.dt', function () {
        t.column(0, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
            cell.innerHTML = i+1;
        } );
        // t.column(7, {search:'applied', order:'applied'}).nodes().each( function (cell, i) {
        //     cell.innerHTML = i+1;
        // } );
        } ).draw();
        }, 300)
    },
    url(kode_wilayah){
      let routeData = this.$router.resolve(
        {
          path: `/rekapitulasi/detailpm/${kode_wilayah}`, 
          // query: {'dataParameter': kode_wilayah}
        });
        window.open(routeData.href, '_blank');
        //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
    },
    urlppb(kode_wilayah){
      let routeData = this.$router.resolve(
        {
          path: `/rekapitulasi/detailppb/${kode_wilayah}`, 
          // query: {'dataParameter': kode_wilayah}
        });
        window.open(routeData.href, '_blank');
        //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
    },
    urlhipmi(kode_wilayah){
      let routeData = this.$router.resolve(
        {
          path: `/rekapitulasi/detailhipmi/${kode_wilayah}`, 
          // query: {'dataParameter': kode_wilayah}
        });
        window.open(routeData.href, '_blank');
        //                window.open(`/ppk/detailpm/${kode_wilayah}`, '_blank')
    },
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    }
  },
  created(){
    //   this.getuser(this.user)
    // this.getlocaldata()
  }
};
</script>
